import {blue, blueGrey, grey, yellow} from "@mui/material/colors"
import { css } from "@emotion/css"
import React from "react";
import {primary} from "../const";

export function Header() {
    return (
        <div className={sidebar}>
            <div className={titleCss}>
                <img src="/logo200h.png" className={logoCss} />
                <div style={{width: 8}} />
                <div>Testing Taxi</div>
            </div>
        </div>
    )
}

const sidebarList = css({
    flex: 1,
    padding: 2,
})

const sidebarItem = css({
    padding: 8,
    paddingLeft: 12,
    borderRadius: 8,
    cursor: "pointer",
    fontSize: "0.85rem",
    fontWeight: "600",
    color: grey["700"],
    border: "2px solid transparent",

    "&.selected": {
        color: "black",
        backgroundColor: primary["800"],
        border: "2px solid " + primary["800"],
    },

    "&:hover": {
        border: "2px solid " + primary["900"],

    }
})

const titleCss = css({
   backgroundColor: blueGrey["200"],
   color: "black",
    padding: 20,
    paddingTop: 4,
    paddingBottom: 4,
    fontWeight: "bold",
    alignItems: "center",
    display: "flex",
})

const logoCss = css({
    height: 50,
    backgroundColor: "white",
    borderRadius: 4,
})
const sidebar = css({
    height: "100%",
    borderRight: "1px solid " + grey["200"]
})