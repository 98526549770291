import { SubAPI } from "./SubAPI";

export class NotesAPI extends SubAPI {
    list(props: {
        clientUuid: string;
        domain: string;
        offset: number;
        search?: string;
    }) {
        return this.fetcher.get<Paginated<Note>>("/api/notes", props);
    }

    listDomains(props: {
        clientUuid: string;
        offset: number;
    }) {
        return this.fetcher.get<Paginated<{
            domain: string;
        }>>("/api/domains", props);
    }
}

export type Paginated<T> = {
    data: T[];
    count: number;
}

type DateString = string

export type Note = {
    id: number;
    urlPattern: string;
    urlExample: string;
    note: string;
    createdAt: DateString;
    updatedAt: DateString;
    archived: boolean;
}
