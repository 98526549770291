import ListItemButton from "@mui/material/ListItemButton";
import React, {PropsWithChildren} from "react";
import { Header } from "./Header";
import {blueGrey, grey} from "@mui/material/colors";
import {Link} from "react-router-dom";

export function Frame(props: PropsWithChildren<{}>) {
    return (
        <div style={{
            position: "absolute", top: 0, left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgb(231, 235, 240)",
        }}>
            <div>
                <Header />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "stretch",
                flex: 1,
                overflow: "hidden",
                flexWrap: "nowrap"
            }}>
                <div style={{
                    width: 160,
                    display: "flex",
                    flexDirection: "column",
                    wordBreak: "break-all",
                    paddingRight: 8,
                }}>
                    <div style={{height: 8}} />
                    <a href="https://testing.taxi/" style={{textDecoration: "none", color: "initial"}}>
                        <ListItemButton style={{
                            borderTopRightRadius: 20,
                            borderBottomRightRadius: 20,
                            // backgroundColor: blueGrey["100"],
                            marginRight: 4,
                            marginBottom: 2,
                        }}>
                            Taxi Home
                        </ListItemButton>
                    </a>
                    <Link to={window.location.toString()} style={{textDecoration: "none", color: "initial"}}>
                        <ListItemButton selected style={{
                            borderTopRightRadius: 20,
                            borderBottomRightRadius: 20,
                            backgroundColor: blueGrey["200"],
                            marginRight: 4,
                        }}>
                            All Notes
                        </ListItemButton>
                    </Link>
                    <div style={{flex:1}}>
                    </div>
                    <div style={{padding: 20}}>
                        <div>Need help?</div>
                        <a href={"mailto:nate@testingtaxi.com"}>nate@<br />testingtaxi.com</a>
                    </div>
                    <div style={{padding: 20}}>
                        <a href={"https://testing.taxi/our-privacy-policy/"}>Privacy</a>
                    </div>
                </div>
                <div style={{
                    display: "flex", flexDirection: "column",
                    flex: 1, overflow: "auto",
                }}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}