import { SubAPI } from "./SubAPI";

export class LibraryAPI extends SubAPI {
    submit(props: {

    }) {
        return this.fetcher.post<{
            count: number
        }>("/api/chrome-extension/library-item", props);
    }
}
