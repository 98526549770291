import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";


export function Redirect(props: {
    to: (input: any) => string;
}) {
    const nav = useNavigate()
    const params = useParams();

    useEffect(() => {
        nav(props.to(params))
    }, [props.to, nav, params])

    return null;
}