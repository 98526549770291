import {Frame} from "../Frame";

export function NotFound() {
    return (
        <Frame>
            <div style={{padding: 20}}>
                <div>Hrm, that's not found.</div>
                If you think this is a bug, please let us know <a href="mailto:nate@testingtaxi.com">nate@testingtaxi.com</a>
            </div>
        </Frame>
    )
}