import {APIBase} from "nate-react-api-helpers";
import {NotesAPI} from "./NotesAPI";
import {DomainsAPI} from "./DomainsAPI";
import { LibraryAPI } from "./LibraryAPI";

class API extends APIBase {
    notes = new NotesAPI(this)
    domains = new DomainsAPI(this)
    library = new LibraryAPI(this);

    constructor() {
        super({
            jwtRefreshEndpoint: "/api/auth/refresh"
        });

        this.fetcher.preflight.push((input) => {
            if(input.path.startsWith("http")) return input;
            return Object.assign({}, input, {
                path: "https://api.testing.taxi" + input.path
            })
        });

    }

    ping() {
        return this.fetcher.get("/api/ping");
    }
}

export const api = new API();