import {Note} from "../../api/NotesAPI";
import {grey} from "@mui/material/colors";
import moment from "moment";

export function NoteRow(props: {
    value: Note
}) {

    const n = props.value;

    return (
        <div style={{padding: 8, paddingTop: 4, paddingBottom: 4}}>
            <div style={{
                fontSize: "0.7rem", color: grey["800"],
                display: "flex",
                flexDirection: "row",
            }}>
                <div>
                    <a style={{color: "inherit"}} href={n.urlExample}>{removeDomain(n.urlPattern)}</a>
                </div>
            </div>
            <div style={{whiteSpace: "pre-wrap", paddingBottom: 2, paddingTop: 2}}>{n.note}</div>
            <div style={{
                fontSize: "0.7rem", color: grey["800"],
                display: "flex",
                flexDirection: "row",
            }}>
                <div>Updated on {moment(n.updatedAt).format("MMM D [at] hh:mm a")}</div>
            </div>
        </div>
    )
}

function domain(str: string) {
    return str.replace(/\/\/(.*?)\/.*$/, "$1")
}

function removeDomain(str: string) {
    return str.replace(/\/\/.*?\//, "/")
}