import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import { NotFound } from './pages/notFound/NotFound';
import { Redirect } from './pages/Redirect';
import {Notes} from "./pages/notes/Notes";
import { LibrarySubmission } from './pages/librarySubmission/LibrarySubmission';
import { RenderPopout } from './pages/popout/RenderPopout';

function App() {
  return (
      <BrowserRouter>
        <Routes>
            <Route path="/library-submission" element={<LibrarySubmission />} />
            <Route path="/notes" element={<Notes />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="/chrome-ext/render" element={<RenderPopout />} />
            <Route path="*" element={<Redirect to={() => "/not-found"} />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
