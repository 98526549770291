
import React, {PropsWithChildren} from "react";
import {IconButton, Tooltip} from "@mui/material";
import {css} from "@emotion/css";
import {blueGrey, grey} from "@mui/material/colors";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'notistack'


export function CopyBox(props: PropsWithChildren<{
    value: string;
    subtle?: boolean;
}>) {
    const showIcon = !props.subtle;


    return (
        <div style={{
            display: "flex", alignItems: "flex-start", padding: 8,
            flex: 1,
        }} className={(props.subtle ? subtleCls : "") + " " + cls}
             onClick={() => {
                 navigator.clipboard.writeText(props.value);
             }}
        >
            <div style={{flex: 1, paddingTop: showIcon ? 5 : undefined, wordBreak: "break-all"}}>
                {props.children}
            </div>
            {showIcon && <div>
                <IconButton size="small" disableRipple>
                    <ContentCopyIcon fontSize="inherit" color="inherit" />
                </IconButton>
            </div>}
        </div>
    )
}

const subtleCls = css({
    "div&": {
        backgroundColor: "rgba(236,239,241,0.57)",
        border: "1px solid transparent",
        "&:hover": {
            backgroundColor: blueGrey["100"],
        }
    }
})

const cls = css({
    margin: 2,
    borderRadius: 4,
    border: "1px solid #ccc",
    backgroundColor: grey["100"],
    cursor: "pointer",

    "&:hover": {
        backgroundColor: blueGrey["100"],
    }
})