import {useSearchParams} from "react-router-dom";
import {useState} from "react";
import {Frame} from "../Frame";
import {
    Alert,
    Button,
    Card,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {css} from "@emotion/css";
import ReactMarkdown from "react-markdown";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { deepOrange, green, grey } from "@mui/material/colors";
import { CopyBox } from "./CopyBox";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";

type Item = {
    textValue: string;

    explain: string;
    where:   string;
    check:   string;
}

export function LibrarySubmission() {
    const [params, setParams] = useSearchParams();
    const [author, setAuthor] = useState(params.get("author") || "")
    const [clientUuid, setClientUuid] = useState(params.get("clientUuid") || "");
    const [tagLine, setTagLine] = useState("");
    const [category, setCategory] = useState("-");
    const [explaination, setExplaination] = useState("");
    const [items, setItems] = useState<Item[]>([]);
    const [readMoreURL, setReadMoreURL] = useState("");
    const [appliesToDesc, setAppliesToDesc] = useState("")

    const action = useAsyncAction(async (input) => {
        const result = await api.library.submit(input)

        setTagLine("");
        setCategory("-");
        setExplaination("");
        setAppliesToDesc("")
        setItems([]);
        setReadMoreURL("");

        return result;
    }, [])

    return (
        <Frame>
            <form
                style={{display: "flex", flexDirection: "column", flex: 1, overflow: "hidden"}}
                onSubmit={e => {
                    e.preventDefault();
                    action.callback({
                        author,
                        clientUuid,
                        tagLine,
                        category,
                        description: explaination,
                        appliesToDescription: appliesToDesc,
                        readMoreUrl: readMoreURL,
                        details: items,
                    })
                }}
            >
                {(action.loading || action.error) && action.LoadingElement}
                {action.result && <Alert>
                    You've contributed {action.result.count} items total. Thank you!
                </Alert>}

                <div style={{display: "flex", flexDirection: "column", overflow: "auto"}}>
                    <div className={formRow}>
                        <div>
                            <div style={{height: 10}} />
                            <TextField fullWidth label="Tag line" value={tagLine} onChange={e => setTagLine(e.target.value)} />
                        </div>
                        <div>
                            <i>Please don't let title wrap to next line</i>
                            <div>Preview</div>
                            <Card className={cardStyle}>
                                <div style={{width: 266, display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                    <div style={{paddingTop: 10, fontSize: "0.9rem", fontFamily: "system-ui"}}>{tagLine}</div>
                                    <div style={{
                                        fontSize: "0.6rem",
                                        borderRadius: 4,
                                        border: "1px solid rgb(238, 238, 238)",
                                        padding: "1px 6px 2px",
                                        backgroundColor: "rgb(238, 238, 238)",
                                        marginTop: 5,
                                        marginBottom: 5,
                                    }}>
                                        {category}
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className={formRow}>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel>Category</InputLabel>
                                <Select label="Category" value={category} onChange={e => setCategory(e.target.value as any)}>
                                    <MenuItem value="-">Choose a category</MenuItem>
                                    <MenuItem value="Security">Security</MenuItem>
                                    <MenuItem value="Usability">Usability</MenuItem>
                                    <MenuItem value="Accessibility">Accessibility</MenuItem>
                                    <MenuItem value="Functionality">Functionality</MenuItem>
                                    <MenuItem value="Performance">Performance</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div><div className={formRow}>
                        <div>
                            <TextField
                                fullWidth multiline rows={4} label="Applies To"
                                value={appliesToDesc} onChange={e => setAppliesToDesc(e.target.value)}
                                helperText={"e.g. text inputs, login forms, search fields etc."}
                            />
                        </div>
                    </div>
                    <div style={{height: 20}} />
                    <div className={formRow}>
                        <div>
                            <TextField fullWidth multiline rows={4} label="Explaination (markdown)" value={explaination} onChange={e => setExplaination(e.target.value)} />
                        </div>
                        <div>
                            <div>
                                <i>
                                    <div>We need to check....</div>
                                    <div>This is important because...</div>
                                </i>
                                <div>Preview:</div>
                            </div>
                            <Card className={cardStyle}>
                                <div style={{width: 310, fontSize: "0.7rem"}}>
                                    <ReactMarkdown>{explaination}</ReactMarkdown>
                                </div>
                            </Card>
                        </div>
                    </div>
                    {items.map((itm, index) => <div key={index.toString()} className={formRow}>
                        <div>
                            Check Item:
                            <div style={{marginTop: 10, marginBottom: 10}}>
                                <TextField fullWidth label="Copy/Paste/Inject Text" value={itm.textValue} onChange={e => {
                                    setItems(items.map(ii => {
                                        if(ii === itm) {
                                            return Object.assign({}, ii, {
                                                textValue: e.target.value
                                            })
                                        }

                                        return ii;
                                    }))
                                }} />
                            </div><div style={{marginBottom: 10}}>
                                <TextField fullWidth label="Explain what this does" value={itm.explain} onChange={e => {
                                    setItems(items.map(ii => {
                                        if(ii === itm) {
                                            return Object.assign({}, ii, {
                                                explain: e.target.value
                                            })
                                        }

                                        return ii;
                                    }))
                                }} helperText={"May prefer to put in general description if it's repetitive and the same for each"} />
                            </div><div style={{marginBottom: 10}}>
                                <TextField fullWidth label="How to know if the check passed/failed" value={itm.check} onChange={e => {
                                    setItems(items.map(ii => {
                                        if(ii === itm) {
                                            return Object.assign({}, ii, {
                                                check: e.target.value
                                            })
                                        }

                                        return ii;
                                    }))
                                }} helperText={"May prefer to put in general description if it's repetitive and the same for each"} />
                            </div><div style={{marginBottom: 10}}>
                                <TextField fullWidth label="Where to try this value (if not obvious)" value={itm.where} onChange={e => {
                                    setItems(items.map(ii => {
                                        if(ii === itm) {
                                            return Object.assign({}, ii, {
                                                where: e.target.value
                                            })
                                        }

                                        return ii;
                                    }))
                                }} helperText={"e.g. email fields"} />
                            </div>
                            <div>
                                <Button onClick={() => setItems(items.filter(ii => ii !== itm))}>Remove Item</Button>
                            </div>
                        </div>
                        <div>
                            <Card className={cardStyle}>
                                <div style={{width: 310}}>
                                    <div style={{height: 30}} />
                                    {itm.textValue && <div>
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <CopyBox value={itm.textValue}>
                                                {itm.textValue}
                                            </CopyBox>
                                        </div>
                                    </div>}
                                    <div style={{height: 16}} />
                                    {itm.where && <div style={{display: "flex", flexDirection: "row", paddingBottom: 4, alignItems: "center"}}>
                                        <div className={iconWrapper} style={{color: deepOrange["700"]}}>
                                            <MyLocationIcon fontSize="inherit" color="inherit" />
                                        </div>
                                        <div style={{width: 14}} />
                                        <div style={{flex: 1}}>{itm.where}</div>
                                    </div>}
                                    {itm.check && <div style={{display: "flex", flexDirection: "row", paddingBottom: 4}}>
                                        <div className={iconWrapper} style={{color: green["700"]}}>
                                            <CheckCircleIcon fontSize="inherit" color="inherit" />
                                        </div>
                                        <div style={{width: 14}} />
                                        <div style={{flex: 1}}>
                                            <ReactMarkdown className={markdownNoTopPad}>{itm.check}</ReactMarkdown>
                                        </div>
                                    </div>}
                                    {itm.explain && <div style={{display: "flex", flexDirection: "row"}}>
                                        <div className={iconWrapper} style={{color: grey["700"]}}>
                                            <InfoIcon fontSize="inherit" color="inherit" />
                                        </div>
                                        <div style={{width: 14}} />
                                        <div style={{flex: 1}}>{itm.explain}</div>
                                    </div>}
                                    <div style={{height: 10}} />
                                </div>
                            </Card>
                        </div>
                    </div>)}
                    <div className={formRow}>
                        <Button onClick={() => setItems([...items, {
                            textValue: "",
                            explain: "",
                            check: "",
                            where: "",
                        }])}>Add Item</Button>
                    </div>
                    <div className={formRow}>
                        <div>
                            <TextField fullWidth label="Read More URL"
                                       value={readMoreURL}
                                       onChange={e => setReadMoreURL(e.target.value)} />
                        </div>
                        <div>
                            <div>
                                <div>Preview:</div>
                            </div>
                            <Card className={cardStyle}>
                                <div style={{width: 310, fontSize: "0.7rem"}}>
                                    <a href={readMoreURL} style={{color: grey["800"]}}>{readMoreURL}</a>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className={formRow}>
                        <div>
                            <TextField label="Author" disabled value={author} onChange={e => setAuthor(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className={formRow}>
                    <Button variant="contained" color="primary" type="submit">Submit</Button>
                </div>
            </form>
        </Frame>
    )
}

const formRow = css({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
    marginBottom: 10,

    "& > div:first-child": {
        width: 250,
    },
    "& > div:not(:first-child)": {
        flex: 1,
        paddingLeft: 10,
    }
})

const cardStyle = css({
    padding: 8,
    display: "inline-block",
    marginTop: 10,
})


const iconWrapper = css({
    fontSize: "1.4rem",
    opacity: 0.7,
    paddingTop: 0
})


const markdownNoTopPad = css({
    "& > *:first-child": {
        marginTop: 0,
    },
    "& > *:last-child": {
        marginBottom: 0,
    }
})