import CircularProgress from "@mui/material/CircularProgress";
import {PropsWithChildren, useEffect, useRef, useState} from "react";
import {grey} from "@mui/material/colors";
import { css } from "@emotion/css";

export const lightGreyTextCls = css({
    color: grey["600"],

    ".darkMode &": {
        color: grey["400"]
    }
})

export function RenderPopout() {
    const [status, setStatus] = useState<{
        url: string;
        description: string
    }|null>(null);

    useEffect(() => {
        const search = new URLSearchParams(window.location.search);
        const title = search.get("title")
        if(title) {
            document.title = title;
        }

        setStatus({
            url: search.get("url") || "",
            description: search.get("description") || ""
        })
    }, []);

    const imageRef = useRef<HTMLImageElement|null>()
    if(!status) return null;

    return (
        <Wrapper>
            <div style={{backgroundColor: "black", color: "white", fontSize: "1rem", width: "100%"}}>
                <div style={{padding: 10}}>
                    {status.description}
                </div>
            </div>
            <img ref={r => {
                imageRef.current = r;
            }} src={status.url} onLoad={e => {

                const img = imageRef.current;
                if(!img) return;

                let limitingWidth = Math.min(img.naturalWidth, window.screen.availWidth * 0.8, 1200);
                let limitingHeight = Math.min(img.naturalHeight, window.screen.availHeight * 0.8, 800);

                const wRatio = img.naturalWidth / limitingWidth
                const hRatio = img.naturalHeight / limitingHeight
                if(wRatio > hRatio) {
                    limitingHeight = img.naturalHeight / wRatio;
                } else {
                    limitingWidth = img.naturalWidth / hRatio;
                }

                window.resizeTo(limitingWidth, limitingHeight + 100)
            }} />
        </Wrapper>
    )
}

function Wrapper(props: PropsWithChildren<{}>) {
    const search = new URLSearchParams(window.location.search);
    const darkMode = search.get("darkMode") === "true";

    useEffect(() => {
        if(darkMode) {
            document.body.classList.add("darkMode");
            document.body.style.color = grey["100"];
        } else {
            document.body.classList.remove("darkMode");
            document.body.style.color = "black"
        }
    }, [darkMode]);

    return (
        <div style={{height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
            {props.children}
        </div>
    )
}
