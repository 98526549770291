import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {MenuItem, Select} from "@mui/material";

export function DomainSelector(props: {
    clientUuid: string;
    value: string | null
    onChange: (value: string) => void
}) {
    const domains = useAsync2((input) => {
        return api.domains.list(input)
    }, {
        clientUuid: props.clientUuid,
        offset: 0,
    }, [props.clientUuid])

    return (
        <Select size="small" value={props.value || "-"} onChange={e => {
            const value = e.target.value;
            if(value === "-") return;

            props.onChange(value);
        }}>
            {domains.loadingOrError && <MenuItem value={props.value || "-"}>{domains.LoadingOrErrorElement}</MenuItem>}
            {domains.result?.data.map(d => (
                <MenuItem key={d.domain} value={d.domain}>{d.domain}</MenuItem>
            ))}
        </Select>
    )
}