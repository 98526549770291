import { Card } from "../Card";
import {useSearchParams} from "react-router-dom";
import {Frame} from "../Frame";
import {useAsync2} from "nate-react-api-helpers";
import {useEffect, useState} from "react";
import {api} from "../../api/API";
import {NoteRow} from "./NoteRow";
import {IconButton, TextField} from "@mui/material";
import {DomainSelector} from "./DomainSelector";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";

export function Notes() {
    const [params, setParams] = useSearchParams();
    const domain = params.get("d") || ""
    const client = params.get("k") || ""
    const [search, setSearch] = useState(params.get("search") || "");
    const [offset, setOffset] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const list = useAsync2((input) => {
        return api.notes.list(input)
    }, {
        search,
        offset,
        domain: domain || "",
        clientUuid: client || "",
    }, [search, offset, domain, client])

    const len = list.result?.data.length;
    useEffect(() => {
        if(offset === 0 && len !== undefined) {
            setPageSize(len);
        }
    }, [offset, len]);

    return (
        <Frame>
            <Card>
                <div style={{padding: 8, paddingLeft: 10, display: "flex", alignItems: "center"}}>
                    <TextField label="Search" value={search} variant="outlined" size="small" onChange={e => {
                        setOffset(0);
                        setSearch(e.target.value);
                    }} />
                    <div style={{width: 10}} />
                    <DomainSelector value={domain} clientUuid={client} onChange={value => {
                        const p = new URLSearchParams(params.toString())
                        p.set("d", value)
                        setParams(p)
                    }} />
                </div>
                <div style={{flex: 1, overflow: "auto"}}>
                    {list.LoadingOrErrorElement}
                    {list.result?.data.map(d => (
                        <NoteRow key={d.id.toString()} value={d} />
                    ))}
                    {list.result?.data.length === 0 && (
                        <div style={{padding: 20, textAlign: "center"}}>
                            No notes found
                        </div>
                    )}
                </div>
                <div>
                    <IconButton disabled={offset === 0} onClick={() => {
                        setOffset(offset - pageSize);
                    }}>
                        <ChevronLeft />
                    </IconButton>

                    <IconButton
                        onClick={() => list.result && setOffset(offset + list.result.data.length)}
                        disabled={!list.result ||
                            offset + list.result.data.length >= list.result.count
                        }>
                        <ChevronRight />
                    </IconButton>
                </div>
            </Card>
        </Frame>
    )
}