import { SubAPI } from "./SubAPI";
import {Paginated} from "./NotesAPI";

export class DomainsAPI extends SubAPI {
    list(props: {
        clientUuid: string;
        offset: number;
    }) {
        return this.fetcher.get<Paginated<{
            domain: string;
        }>>("/api/domains", props);
    }
}
