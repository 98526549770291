import {PropsWithChildren} from "react";

export function Card(props: PropsWithChildren<{}>) {
    return (
        <div style={{
            backgroundColor: "white",
            borderTopLeftRadius: 16, marginTop: 8,
            borderBottomLeftRadius: 16, marginBottom: 8,
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "auto",
        }}>
            {props.children}
        </div>
    )
}